/*
CesarCuitino.cl
Visualización de Datos Ranking Proveedores v1.0
*/

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

//const THE_URL = "http://localhost/ranking-admin";
const THE_URL = "https://rankingproveedores.cl/admin";

async function fetchYearsJSON(yearsInArray = false) {
  const response = await fetch(`${THE_URL}/api/read.php?year=list`);
  const years = await response.json();
  if (yearsInArray == true) {
    let onlyYears = [];
    for (let y in years) {
      onlyYears.push(years[y].year);
    }
    return onlyYears;
  }
  return years;
}

const DEFAULT_COMPANY = null;
var YEARS = [];

const noData = `<p style='text-align:center;margin-top:15px;'>No hay datos</p>`;

function masonry(grid, gridCell, gridGutter, dGridCol, tGridCol, mGridCol) {
  let g = document.querySelector(grid),
    gc = document.querySelectorAll(gridCell),
    gcLength = gc.length, // Total number of cells in the masonry
    gHeight = 200, // Initial height of our masonry
    i; // Loop counter
  /*
  console.log("g", g);
  console.log("gcLength", gcLength);
  console.log("gHeight antes", gHeight);*/
  // Calculate the net height of all the cells in the masonry
  for (i = 0; i < gcLength; ++i) {
    gHeight += gc[i].offsetHeight;
    //console.log(gc[i].id, gc[i].offsetHeight + 30);
  } /*
  console.log("gHeight despues", gHeight);
  console.log(`calculo de cells: ${gcLength}`);
  console.log(`calculo de heigh: ${gHeight}`);*/
  /*
   * Calculate and set the masonry height based on the columns
   * provided for big, medium, and small screen devices.
   */
  //console.log("window width", window.innerWidth);
  if (window.innerWidth >= 1200) {
    g.style.height = gHeight / dGridCol + gHeight / (gcLength + 1) + "px";
    //console.log("height desktop", g.style.height);
  } else if (window.innerWidth < 1200 && window.innerWidth >= 768) {
    g.style.height = gHeight / tGridCol + gHeight / (gcLength + 1) - 200 + "px";
    //console.log("height table", g.style.height);
  } else {
    g.style.height = gHeight / mGridCol + gHeight / (gcLength + 1) - 200 + "px";
    //console.log("height mobile", g.style.height);
  }
}

function replaceInArray(str) {
  return str.replace(/\s+/g, "\u00A0");
}

async function uniqueElements(element, selectedYear = DEFAULT_YEAR) {
  /*
  const array = [
    { id: 3, name: 'Central Microscopy', fiscalYear: 2018 },
    { id: 5, name: 'Crystallography Facility', fiscalYear: 2018 },
    { id: 3, name: 'Central Microscopy', fiscalYear: 2017 },
    { id: 5, name: 'Crystallography Facility', fiscalYear: 2017 }
  ];
  */
  const result = [];
  const map = new Map();
  for (const item of element) {
    if (!map.has(item.segment_id)) {
      let resCompanies = await fetch(
        `${THE_URL}/api/read.php?list_companies=${item.segment_id}&anio=${selectedYear}`
      );
      let empresas = await resCompanies.json();
      map.set(item.segment_id, true);
      result.push({
        segment_id: item.segment_id,
        segment_name: item.segment_name,
        year: item.year,
        companies: empresas,
      });
    }
  }
  return result;
}

function toScore(score) {
  return isNaN(parseFloat(score)) ? null : parseFloat(score);
}

function drawChartDonut(id, newData = undefined) {
  //console.log(`drawChartDonut newData ${id}`, newData);
  if (newData !== undefined && newData !== false && newData.length > 0) {
    let data = google.visualization.arrayToDataTable(newData);
    let options = {
      pieHole: 0.5,
    };

    let chart = new google.visualization.PieChart(document.getElementById(id));
    chart.draw(data, options);
  } else {
    let chart = document.getElementById(id);
    chart.innerHTML = noData;
  }
}

function drawChartScoreEvolution(id, newData = undefined) {
  if (newData != undefined && newData != false && newData.length > 0) {
    let data = new google.visualization.DataTable();
    data.addColumn("string", "Año");

    newData[0].forEach(function (item, index) {
      data.addColumn("number", item);
    });

    data.addRows(newData[1]);

    let options = {
      legend: {
        position: "top",
      },
      hAxis: {
        title: "Año",
      },
      vAxis: {
        title: "Nota",
      },
      pointsVisible: true,
      interpolateNulls: true,
    };

    let chart = new google.visualization.LineChart(document.getElementById(id));
    chart.draw(data, options);
  } else {
    let chart = document.getElementById(id);
    chart.innerHTML = noData;
  }
}

function drawChartScoreConsolidado(id, newData = undefined) {
  /*
  newData = [
    [
      "Nota Calidad", "Nota Servicio", "Nota Flexibilidad", "Nota Final"
    ],
    [
      ['2012', null, null, null, 4.6],
      ['2013', 5.6, 6, 5.1, 4.6],
      ['2014', 4.5, 6, 5.1, 4.6],
      ['2015', 6.5, 6, 5.1, 4.6],
      ['2016', 4.5, 6, null, 4.6],
      ['2017', 5, 6, 5.1, 4.6],
      ['2018', 4.5, null, 5.1, 4.6],
      ['2019', 4.5, 6, 5.1, 4.6],
      ['2020', 4.5, 6, 5.1, 4.6],
      ['2021', 4.5, 6, 5.1, 4.6]
    ]
  ];
  */
  if (newData != undefined && newData != false && newData.length > 0) {
    let data = new google.visualization.DataTable();
    data.addColumn("string", "Año");

    newData[0].forEach(function (item, index) {
      data.addColumn("number", item);
    });

    data.addRows(newData[1]);

    let options = {
      legend: {
        position: "top",
      },
      hAxis: {
        title: "Año",
      },
      vAxis: {
        title: "Nota",
      },
      pointsVisible: true,
      interpolateNulls: true,
    };

    let chart = new google.visualization.LineChart(document.getElementById(id));
    chart.draw(data, options);
  } else {
    let chart = document.getElementById(id);
    chart.innerHTML = noData;
  }
}
function isAllZero(Data) {
  let count = 0;
  Data.map((score) => {
    if (typeof score[1] === "number") {
      count = count + score[1];
    }
  });
  if (count > 0) {
    return true;
  } else {
    return false;
  }
}

function drawChartScoreBySegment(id, newData = undefined) {
  if (
    newData !== undefined &&
    newData !== false &&
    newData.length > 0 &&
    isAllZero(newData)
  ) {
    //console.log("drawChartScoreBySegment", newData);
    /*
    let newData= [
      ['Element', 'Density', { role: 'style' }],
      ['Aceros\u00A0Chile', 5.8, '#b87333'],            // RGB value
      ['Elecment', 4, 'silver'],            // English color name
      ['Gold y Silver', 7, 'gold'],
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
      ['Platinum Det', 3.5, 'color: #e5e4e2' ], // CSS-style declaration
   ];*/
    let data = google.visualization.arrayToDataTable(newData);
    let view = new google.visualization.DataView(data);
    view.setColumns([
      0,
      1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation",
      },
      2,
    ]);

    let options = {
      bar: { groupWidth: "75%" },
      legend: { position: "none" },
      hAxis: {
        viewWindow: {
          min: 0,
          max: 7,
        },
        ticks: [0, 1, 2, 3, 4, 5, 6, 7],
      },
    };
    let chart = new google.visualization.BarChart(document.getElementById(id));
    chart.draw(view, options);
  } else {
    let chart = document.getElementById(id);
    chart.innerHTML = noData;
  }
}

async function fetchCompanyDetails(
  selectedYear = "false",
  selectedSegment = "false",
  company_id = "false"
) {
  //console.log(`${THE_URL}/api/read.php?list_companies=${selectedSegment}&anio=${selectedYear}&company_id=${company_id}`)
  let response = await fetch(
    `${THE_URL}/api/read.php?list_companies=${selectedSegment}&anio=${selectedYear}&company_id=${company_id}`
  );

  let json;

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    json = await response.json();

    if (json.result != false && selectedYear == "false") {
      // Aquí iría un proceso similar a la funcion
      // dataForChartScoreEvolution

      let labels = [
        "Nota Calidad",
        "Nota Servicio",
        "Nota Flexibilidad",
        "Nota Final",
      ];
      let rows = [];
      YEARS.forEach(function (year) {
        const result = json.find((obj) => year == obj.year);

        if (result !== undefined) {
          rows.push([
            result.year,
            toScore(result.quality_score),
            toScore(result.service_score),
            toScore(result.flexibility_score),
            toScore(result.final_score),
          ]);
        } else {
          rows.push([year, null, null, null, null]);
        }
      });
      //console.log("row", rows);
      let data = [labels, rows];

      return data;
    } else {
    }
  }

  return json;
}

async function fetchCompanies(selectedYear, selectedCompany, selectedSegment) {
  let response = await fetch(
    `${THE_URL}/api/read.php?list=companies&year=${selectedYear}&segment_id=${selectedSegment}`
  );

  let json;

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    json = await response.json();

    if (json.result != false) {
      let companies = "";
      let list = document.getElementById("selectInfoByCompany");
      let selected = "";

      for (var key in json) {
        if (selectedCompany == json[key].company) {
          selected = "selected";
        } else {
          selected = "";
        }

        companies += `<option value="${json[key].company}" ${selected}>${json[key].company_name}</option>`;
      }
      if (list != null) {
        list.innerHTML = companies;
      }
    } else {
    }
  }

  return json;
}

async function fetchSegments(selectedCompany) {
  let response = await fetch(
    `${THE_URL}/api/read.php?list=segments&company_id=${selectedCompany}`
  );

  let json;

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    json = await response.json();

    let segments = [];
    let selectInfoByCompanySegment = document.getElementById(
      "selectInfoByCompanySegment"
    );
    let selectedSegmentByCompany = document.getElementById(
      "selectedSegmentByCompany"
    );
    let selected = "selected";
    let selectedSegment = "";

    if (json.result != false) {
      for (let key in json) {
        if (selected == "selected") {
          selectedSegment = [json[key].id, json[key].segment_name];
        }
        segments += `<option value="${json[key].id}" ${selected}>${json[key].segment_name}</option>`;
        selected = "";
      }
      if (selectInfoByCompanySegment != null) {
        selectInfoByCompanySegment.innerHTML = segments;
      }
      if (selectedSegmentByCompany != null) {
        selectedSegmentByCompany.innerHTML = selectedSegment[1];
      }

      result = selectedSegment[0];

      //UpdateLastCharts(selectedCompany,selectedSegment[0]);
    } else {
      selectInfoByCompanySegment.innerHTML = "<option>No hay datos</option>";
    }
  }
  return json;
}

// Obtiene las empresas y sus segmentos asociadas al año seleccionado
async function getCompanies(
  selectedCompany = DEFAULT_COMPANY,
  selectedYear = DEFAULT_YEAR
) {
  let loadCompanies = fetchCompanies(selectedYear, selectedCompany);
  let loadSegments = fetchSegments(selectedCompany);

  let values = await Promise.all([loadCompanies, loadSegments]);
  return values;
}

function updateSelectInfoByYear(selectedYear = DEFAULT_YEAR) {
  fetchYearsJSON()
    .then((years) => {
      let yearOptions = "";
      let selectInfoByYear = document.getElementById("selectInfoByYear");
      let selected = "";

      for (let key in years) {
        if (selectedYear == years[key].year) {
          selected = "selected";
        } else {
          selected = "";
        }

        yearOptions += `<option value="${years[key].year}" ${selected}>${years[key].year}</option>`;
      }
      if (selectInfoByYear != null) {
        selectInfoByYear.innerHTML = "";
        selectInfoByYear.insertAdjacentHTML("afterbegin", yearOptions);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function updateCurrentYear(selectedYear = DEFAULT_YEAR) {
  const current_year = document.querySelectorAll(".current-year");
  current_year.forEach(function (element) {
    element.innerHTML = selectedYear;
  });
}

function updateResultsBySegments() {}

async function getInfoByYear(selectedYear = DEFAULT_YEAR) {
  // Refresca el select principal (año)
  updateSelectInfoByYear(selectedYear);

  const response = await fetch(
    `${THE_URL}/api/read.php?year_win=${selectedYear}`
  );

  let json;

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    json = await response.json();
    let segmentsByYearSelect = document.getElementById("selectInfoBySegment");

    if (json.result != false) {
      let segmentsByYearOptions = "";
      let selected = "selected";
      let i = 0;
      let selectedSegment = "";
      let uniqueSegments = await uniqueElements(json, selectedYear);

      uniqueSegments.forEach(function (segment, index) {
        if (i == 0) {
          selectedSegment = segment.segment_id;
          i++;
        }
        segmentsByYearOptions += `<option value="${segment.segment_id}" ${selected}>${segment.segment_name}</option>`;
        selected = "";
      });
      segmentsByYearSelect.innerHTML = segmentsByYearOptions;
      updateCurrentYear(selectedYear);
      loadCharts(selectedYear, selectedSegment, uniqueSegments);
    } else {
      updateCurrentYear(selectedYear);
      let selectedSegment = false;
      let uniqueSegments = false;
      segmentsByYearSelect.innerHTML = `<option value="0" selected>No hay datos</option>`;
      loadCharts(selectedYear, selectedSegment, uniqueSegments);
    }
  }

  let getMentions = fetchMentions(selectedYear);
  let mentions = await getMentions;
  let mentionsContainer = document.getElementById("mentions");

  if (mentions.result != false) {
    let mentionsHTML = ``;
    for (let key in mentions) {
      mentions[key].winner1 != null
        ? (mentionsHTML += `<div class="box_rounded box_rounded_logo mb-3 mx-xl-0 mb-xl-0">
            <h3 class="box_rounded_title">${mentions[key].category}</h3>
            <div class="box_rounded_chart">
              ${
                mentions[key].winner1logo != null
                  ? "<img style='width:180px;max-width:180px;height:auto;display:block;margin:15px auto 15px auto;' src='" +
                    THE_URL +
                    "/" +
                    mentions[key].winner1logo +
                    "'/>"
                  : "<h4 class='logo'>" + mentions[key].winner1 + "</h4>"
              }
            </div>
          </div>`)
        : false;
      mentions[key].winner2 != null
        ? (mentionsHTML += `<div class="box_rounded box_rounded_logo mb-3 mx-xl-0 mb-xl-0">
            <h3 class="box_rounded_title">${mentions[key].category}</h3>
            <div class="box_rounded_chart">
            <h4 class="logo">${mentions[key].winner2}</h4>
            </div>
          </div>`)
        : false;
      mentions[key].winner3 != null
        ? (mentionsHTML += `<div class="box_rounded box_rounded_logo mb-3 mx-xl-0 mb-xl-0">
            <h3 class="box_rounded_title">${mentions[key].category}</h3>
            <div class="box_rounded_chart">
            <h4 class="logo">${mentions[key].winner3}</h4>
            </div>
          </div>`)
        : false;
      mentions[key].winner4 != null
        ? (mentionsHTML += `<div class="box_rounded box_rounded_logo mb-3 mx-xl-0 mb-xl-0">
            <h3 class="box_rounded_title">${mentions[key].category}</h3>
            <div class="box_rounded_chart">
            <h4 class="logo">${mentions[key].winner4}</h4>
            </div>
          </div>`)
        : false;
    }
    mentionsContainer.innerHTML = mentionsHTML;
  } else {
    mentionsContainer.innerHTML = "";
  }
}

function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

async function dataForChartScoreEvolution(segment_id) {
  let response = await fetch(
    `${THE_URL}/api/read.php?segment_id=${segment_id}`
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    let json = await response.json();
    if (json.result != false) {
      let rows = [];
      let companies = [];
      let tempCompanies = [];
      let uniqueCompanies = [];
      let listUniqueCompanies = [];
      let cont = 0;
      let currentCompany = "";
      YEARS.forEach(function (year) {
        rows.push([year]);
        companies.push([]);
        for (let key in json) {
          if (json[key].year == year) {
            if (json[key].final_score == null) {
              json[key].final_score = 0;
            }

            companies[cont].push([
              json[key].company,
              toScore(json[key].final_score),
            ]);
            tempCompanies.push(json[key].company);
            listUniqueCompanies.push(json[key].company);
          }
        }
        uniqueCompanies[cont] = [...new Set(tempCompanies)];
        cont++;
        tempCompanies = [];
      });

      listUniqueCompanies = [...new Set(listUniqueCompanies)];
      let y = 0;
      let found2 = false;

      rows.forEach(function (row, index) {
        for (let i = 0; i < listUniqueCompanies.length; i++) {
          if (companies[index].length > 0) {
            while (found2 == false && y < listUniqueCompanies.length) {
              if (getSafe(() => companies[index][y][0]) != undefined) {
                currentCompany = companies[index][y][0];
              } else {
                currentCompany = null;
              }
              if (listUniqueCompanies[i] == currentCompany) {
                row.push(companies[index][y][1]);
                found2 = true;
              } else {
                if (y == listUniqueCompanies.length - 1) {
                  row.push(null);
                }
              }
              y++;
            }
            y = 0;
            found2 = false;
          } else {
            row.push(null);
          }
        }
      });

      let data = [listUniqueCompanies, rows];
      return data;
    } else {
      //No hay datos
      console.log("No hay datos");
    }
  }
}

async function dataForChartScoreBySegment(
  year = DEFAULT_YEAR,
  segment_id = false,
  chart = false,
  render_id = false
) {
  //console.log(`chart: ${chart}`);

  let response = await fetch(
    `${THE_URL}/api/read.php?year_scores=${year}&segment_id=${segment_id}`
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    let json = await response.json();

    if (json.result != false) {
      //console.log(json);
      let data_chart_quality = [];
      let data_chart_service = [];
      let data_chart_flexibility = [];
      let data_chart_final_score = [];

      let quality_score;
      let service_score;
      let flexibility_score;
      let final_score;

      for (let key in json) {
        quality_score = toScore(json[key].quality_score);
        service_score = toScore(json[key].service_score);
        flexibility_score = toScore(json[key].flexibility_score);
        final_score = toScore(json[key].final_score);

        data_chart_quality.push([]);
        data_chart_quality[key].push(json[key].company);
        data_chart_quality[key].push(quality_score);
        data_chart_quality[key].push("#E3023C");

        data_chart_service.push([]);
        data_chart_service[key].push(json[key].company);
        data_chart_service[key].push(service_score);
        data_chart_service[key].push("#E3023C");

        data_chart_flexibility.push([]);
        data_chart_flexibility[key].push(json[key].company);
        data_chart_flexibility[key].push(flexibility_score);
        data_chart_flexibility[key].push("#E3023C");

        data_chart_final_score.push([]);
        data_chart_final_score[key].push(replaceInArray(json[key].company));
        data_chart_final_score[key].push(final_score);
        data_chart_final_score[key].push("#E3023C");
      }

      data_chart_quality = [
        ["Element", "Nota", { role: "style" }],
        ...data_chart_quality,
      ];
      data_chart_service = [
        ["Element", "Nota", { role: "style" }],
        ...data_chart_service,
      ];
      data_chart_flexibility = [
        ["Element", "Nota", { role: "style" }],
        ...data_chart_flexibility,
      ];
      data_chart_final_score = [
        ["Element", "Nota", { role: "style" }],
        ...data_chart_final_score,
      ];

      if (chart === "quality") {
        return data_chart_quality;
      } else if (chart === "service") {
        return data_chart_service;
      } else if (chart === "flexibility") {
        return data_chart_flexibility;
      } else if (chart === "final_score") {
        return data_chart_final_score;
      } else {
        return false;
      }
    } else {
      //Retorna un array vacío
      return [];
    }
  }
}

async function dataForChartVariableWeighting(
  year = DEFAULT_YEAR,
  segment_id = false
) {
  // PONDERACION DE VARIABLES MAS RELEVATES PARA EVALUADORES (DonutChart)
  let response = await fetch(
    `${THE_URL}/api/read.php?chart=variable_weighting&year=${year}&segment_id=${segment_id}`
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  let json = await response.json();
  if (json.result != false) {
    //console.log(toScore(json[0].quality_weight));
    if (toScore(json[0].quality_weight) != null) {
      let data_chart_variable_weighting = [];
      data_chart_variable_weighting = [
        ["Empresa", "Weighting"],
        ["Calidad", toScore(json[0].quality_weight)],
        ["Servicio", toScore(json[0].service_weight)],
        ["Flexibilidad", toScore(json[0].flexibility_weight)],
      ];
      return data_chart_variable_weighting;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

async function dataForChartSegmentLeader(
  year = DEFAULT_YEAR,
  segment_id = false
) {
  // LIDER DEL SEGMENTO (DonutChart)
  let response = await fetch(
    `${THE_URL}/api/read.php?chart=segment_leader&year=${year}&segment_id=${segment_id}`
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    let json = await response.json();
    if (json.result != false) {
      let data_chart_segment_leader = [];
      let ceros = 0;
      let cont = 0;
      for (let key in json) {
        if (toScore(json[key].segment_leader) > 0) {
          data_chart_segment_leader.push([]);
          data_chart_segment_leader[cont].push(json[key].company);
          data_chart_segment_leader[cont].push(
            toScore(json[key].segment_leader)
          );
          cont++;
        }
      }
      data_chart_segment_leader = [
        ["Element", "Líder segmento"],
        ...data_chart_segment_leader,
      ];

      return data_chart_segment_leader;
      /*
      if (ceros <= 1) {
        return data_chart_segment_leader;
      } else {
        return false;
      }*/
    } else {
      return false;
    }
  }
}

function loadCharts(
  selectedYear = DEFAULT_YEAR,
  selectedSegment = false,
  uniqueSegments = false
) {
  google.charts.load("current", {
    callback: function () {
      let resultsBySegment = document.getElementById("resultsBySegment");

      if (uniqueSegments !== false) {
        let resultsBySegmentBox = "";
        let companies = "";
        let bar_width;
        uniqueSegments.forEach(function (segment, index) {
          segment.companies.forEach(function (item, index) {
            bar_width = `${(parseFloat(item.final_score) * 100) / 7}%`;
            companies += `
              <li class="company-detail" data-company-id="${item.company}" data-company-name="${item.company_name}" data-year="${item.year}" data-segment-id="${item.segment}" data-segment-name="${segment.segment_name}">
              <span class="left"><span>${item.company_name}</span></span> <span class="right"><span class="bar" style="width:${bar_width}">${item.final_score}</span></span>
              </li>`;
          });
          resultsBySegmentBox += `
          <div class="box_rounded mb-3 masonry-brick" id="box_segment_${index}">
          <h3 class="box_rounded_title">${segment.segment_name}</h3>
          <div class="box_rounded_list">
          <div id="companies-container" class="companies-container loading row">
                <div class="company-name">
                <ul class="list-companies">${companies}</ul>
                </div>
            </div>
          </div>
          </div>`;
          companies = "";
        });
        resultsBySegment.innerHTML = resultsBySegmentBox;
        if (window.innerWidth >= 768) {
          masonry(".masonry", ".masonry-brick", 8, 3, 2, 1);
        }
        let company_list = document.querySelectorAll(".company-detail");
        company_list.forEach((el) => {
          el.addEventListener("click", (e) => {
            handleClickCompany(e.target.closest(".company-detail"));
          });
        });
      } else {
        resultsBySegment.innerHTML =
          "<p style='text-align:center;margin-top:20px'>No hay datos</p>";
        masonry(".masonry", ".masonry-brick", 8, 3, 2, 1);
      }
      updateChartsInfoBySegmentSection(selectedYear, selectedSegment);
    },
    packages: ["corechart"],
  });
}

function updateChartsInfoBySegmentSection(
  selectedYear = DEFAULT_YEAR,
  selectedSegment = false
) {
  dataForChartScoreEvolution(selectedSegment)
    .then((myData) => {
      drawChartScoreEvolution(`chart-evolucion-nota-final`, myData);
    })
    .catch((e) => console.log(e));
  dataForChartScoreBySegment(selectedYear, selectedSegment, "quality")
    .then((myData) => {
      drawChartScoreBySegment("chart-nota-segmento-1", myData);
    })
    .catch((e) => console.log(e));

  dataForChartScoreBySegment(selectedYear, selectedSegment, "service")
    .then((myData) => {
      drawChartScoreBySegment("chart-nota-segmento-2", myData);
    })
    .catch((e) => console.log(e));

  dataForChartScoreBySegment(selectedYear, selectedSegment, "flexibility")
    .then((myData) => {
      drawChartScoreBySegment("chart-nota-segmento-3", myData);
    })
    .catch((e) => console.log(e));

  dataForChartSegmentLeader(selectedYear, selectedSegment)
    .then((myData) => {
      drawChartDonut("chart-lider-segmento", myData);
    })
    .catch((e) => console.log(e));

  dataForChartVariableWeighting(selectedYear, selectedSegment)
    .then((myData) => {
      drawChartDonut("chart-variable-weighting", myData);
    })
    .catch((e) => console.log(e));
}

async function fetchMentions(year = DEFAULT_YEAR) {
  let response = await fetch(
    `${THE_URL}/api/read.php?table=spontaneous_mentions&year=${year}`
  );

  let json;

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    json = await response.json();
    return json;
  }
}

async function getCompanyDetails(
  year = DEFAULT_YEAR,
  segment_id = false,
  company_id = false
) {
  const data1 = fetchCompanyDetails(year, segment_id, company_id);
  const data2 = fetchCompanyDetails(year, "false", company_id);
  const data3 = fetchCompanyDetails("false", segment_id, company_id);

  const values = await Promise.all([data1, data2, data3]);
  //console.log("getCompanyDetails all values");
  //console.log("values", values);
  return values;
}

function handleClickCompany(e) {
  const company_id = e.getAttribute("data-company-id");
  const company_name = e.getAttribute("data-company-name");
  const year = e.getAttribute("data-year");
  const segment_id = e.getAttribute("data-segment-id");
  const segment_name = e.getAttribute("data-segment-name");
  const lightbox = document.getElementById("lightbox");

  // Aquí tenemos que hacer el fetch para los datos especificos de la empresa
  getCompanyDetails(year, segment_id, company_id)
    .then((myData) => {
      //console.log(myData[1]);
      let segments = "";
      myData[1].forEach(function (element, index) {
        segments += `<li>${element.name}</li>`;
      });
      //console.log(segments);
      const lightboxContainer = `
      <div class="company-data container" style="background-color:#fff">
                            <div class="row lightbox-header">
                                <div class="col-10">
                                    ${company_name} ${year} - ${segment_name}
                                </div>
                                <div class="col-2 text-end">
                                <img id="lightboxClose" src="/icon-close.svg" width="16" height="16" alt="Cerrar">
                                </div>
                            </div>
                            <div class="row" style="margin-bottom:15px;">
                                <div class="col-12 col-xl-4">
                                    <div class="box_rounded" style="margin-bottom:15px">
                                        <h3 class="box_rounded_title">Notas Empresa ${year}</h3>
                                        <div class="box_rounded_chart" style="width:100%;height:auto;min-height:260px;">
                                        <ul class="list">
                                            <li>Nota Calidad <span>${myData[0][0].quality_score}</span></li>
                                            <li>Nota Servicio <span>${myData[0][0].service_score}</span></li>
                                            <li>Nota Flexibilidad <span>${myData[0][0].flexibility_score}</span></li>
                                            <li>Nota Final <span>${myData[0][0].final_score}</span></li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div class="box_rounded" style="margin-bottom:15px">
                                        <h3 class="box_rounded_title">Otros segmentos donde esta empresa fue evaluada en ${year}</h3>
                                        <div class="box_rounded_chart" style="width:100%;height:auto;min-height:260px;">
                                            <ul class="list">
                                                ${segments}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-8">
                                    <div class="box_rounded">
                                        <h3 class="box_rounded_title">Consolidado de Notas (Calidad / Servicio / Flexibilidad / Final)</h3>
                                        <div class="box_rounded_chart" style="width:100%;height:auto;min-height:260px;padding:0;">
                                        <div id="chart-consolidado-notas" style="width:100%;height:500px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
      `;

      lightbox.innerHTML = lightboxContainer;
      lightbox.classList.add("show");
      drawChartScoreConsolidado("chart-consolidado-notas", myData[2]);
      document.body.classList.add("overflow-hidden");
      // Oculta el lightbox
      lightboxClose.addEventListener("click", (e) => {
        lightbox.classList.remove("show");
        lightbox.innerHTML = "";
        document.body.classList.remove("overflow-hidden");
      });
    })
    .catch((e) => console.log(e));
  // Despues ver testear select de año
}

document.addEventListener("DOMContentLoaded", () => {
  function debounce(cb, delay = 500) {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }
  function handleResize() {
    let selectedYear = document.querySelector("#selectInfoByYear").value;
    selectedYear === "" && DEFAULT_YEAR;
    getInfoByYear(selectedYear);
    if (window.innerWidth >= 768) {
      masonry(".masonry", ".masonry-brick", 8, 3, 2, 1);
    }
  }
  const updateDebounceOnResize = debounce(() => {
    handleResize();
  });

  window.addEventListener("resize", function () {
    updateDebounceOnResize();
  });

  fetch(`${THE_URL}/api/read.php?year=list`)
    .then((response) => {
      if (response.ok) {
        response.json().then(function (yearsJSON) {
          for (let y in yearsJSON) {
            YEARS.push(yearsJSON[y].year);
          }
          var [DEFAULT_YEAR] = YEARS.slice(-1);
          getInfoByYear(DEFAULT_YEAR);
        });
      } else {
        console.log("Respuesta de red OK pero respuesta HTTP no OK");
      }
    })
    .catch((e) => console.log(e));

  // Tenemos en total 2 selectores en el FrontEnd que guardamos en constantes.
  const selectInfoByYear = document.getElementById("selectInfoByYear");
  const selectInfoBySegment = document.getElementById("selectInfoBySegment");
  if (selectInfoByYear != null) {
    selectInfoByYear.addEventListener("change", (e) => {
      document.getElementById(
        "resultsBySegment"
      ).innerHTML = `<div class="loadingio-spinner-rolling-petkgh32j7q" style="background-color:#fff">
      <div class="ldio-8o6zb7kr3xt"><div></div></div>
    </div>`;
      getInfoByYear(e.target.value);
    });
  }
  if (selectInfoBySegment != null) {
    selectInfoBySegment.addEventListener("change", (e) => {
      let selectedYear = document.querySelector("#selectInfoByYear").value;
      updateCurrentYear(selectedYear);
      updateChartsInfoBySegmentSection(selectedYear, e.target.value);
    });
  }

  const buttonView = document.getElementById("buttonView");
  const desc = document.getElementById("desc");
  const classes = desc.classList;
  if (buttonView != null) {
    buttonView.addEventListener("click", (e) => {
      const result = classes.toggle("expand");
      if (result) {
        buttonView.innerHTML = `Cerrar Instrucciones`;
      } else {
        buttonView.innerHTML = `Ver Instrucciones`;
      }
    });
  }

  const buttonPDF = document.getElementById("buttonDownloadPDF");
  if (buttonPDF !== null) {
    buttonPDF.addEventListener("click", () => {
      buttonPDF.disabled = true;
      buttonPDF.textContent = "Generando PDF...";
      const lightbox = document.getElementById("lightbox");
      lightbox.innerHTML = `<div class="loadingio-spinner-rolling-petkgh32j7q" style="margin-top:90px"><div class="ldio-8o6zb7kr3xt">
      <div></div>
      </div></div>
    <p>Generando PDF, espere un momento...</p>`;
      lightbox.classList.add("show");
      const viewPort = document.querySelector("meta[name=viewport]");
      viewPort.setAttribute("content", "width=1200");

      const logo = document.querySelector(".header_logo");
      const firstPage = document.getElementById("firstPage");
      const secondPage = document.getElementById("secondPage");

      function createPDF() {
        const doc = new jsPDF({
          unit: "px",
          format: [770, 1269],
        }); /*Proporcional Oficio*/
        getCanvas(logo).then(function (canvas) {
          const imgLogo = canvas.toDataURL("image/png");
          doc.addImage(imgLogo, "PNG", 25, 20, 160, 62);
          getCanvas(firstPage).then(function (canvas) {
            const imgFirstPage = canvas.toDataURL("image/png");
            const imgFirstPageProps = doc.getImageProperties(imgFirstPage);
            const imgFirstPageWidth = doc.internal.pageSize.getWidth();
            const imgFirstPageHeight =
              (imgFirstPageProps.height * imgFirstPageWidth) /
              imgFirstPageProps.width;
            doc.addImage(
              imgFirstPage,
              "PNG",
              20,
              100,
              imgFirstPageWidth - 40,
              imgFirstPageHeight - 40
            );
            getCanvas(secondPage).then(function (canvas) {
              const imgSecondPage = canvas.toDataURL("image/png");
              const imgSecondPageProps = doc.getImageProperties(imgSecondPage);
              const imgSecondPageWidth = doc.internal.pageSize.getWidth();
              const imgSecondPageHeight =
                (imgSecondPageProps.height * imgSecondPageWidth) /
                imgSecondPageProps.width;
              const today = new Date().toLocaleString();
              doc.addPage();
              doc.addImage(
                imgSecondPage,
                "PNG",
                20,
                20,
                imgSecondPageWidth - 40,
                imgSecondPageHeight - 40
              );
              doc.save("Ranking_proveedores_" + today + ".pdf");
              buttonPDF.disabled = false;
              buttonPDF.textContent = "Descargar Datos";
              viewPort.setAttribute(
                "content",
                "width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no"
              );
              lightbox.classList.remove("show");
              lightbox.innerHTML = "";
            });
          });
        });
      }

      function getCanvas(element) {
        return html2canvas(element, {
          imageTimeout: 0,
          removeContainer: true,
        });
      }
      setTimeout(createPDF, 3000);
    });
  }
});

if (module.hot) {
  module.hot.accept();
}
